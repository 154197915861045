import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import Header from './header';
import '../css/normalize.css';
import '../css/themasterlist.css';
import svg from '!raw-loader!../icons/themasterlist-SVG-sprite.svg';
var iconsSvg = svg;
iconsSvg = iconsSvg.replace(/<title[^>]*>[^<]*<\/title>/g, '');

const Layout = ({ children }) => (
  <StaticQuery
	  query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
	  render={data => (
		  <div className="layout">
			  <Header siteTitle={data.site.siteMetadata.title} />
			  <div id="icons-loaders">
				  <div hidden dangerouslySetInnerHTML={{ __html: iconsSvg}}/>
			  </div>
			  <div>
				  {children}
				  <footer>
					  © 2018
				  </footer>
			  </div>
		  </div>
	  )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
