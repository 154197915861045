import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';


class Header extends React.Component {
	sendClickEvent(element) {
		element = element || "unknown";
		element = "toolbar-" + element;
		// console.log("Clicked on", element);
		if (window.ga) {
			window.ga(`send`, `event`, {
				eventCategory: `toolbar`,
				eventAction: `click`,
				eventLabel: element,
				hitCallback: function() {}
			});
		}
	}

	render() {
		const { siteTitle } = this.props;
		return (
			<div className="header">

				<div className="header-column left">
					<Link onClick={() => this.sendClickEvent('logo')} className="link logo" to="/">
						<svg className="icon"><use xlinkHref="#logo" /></svg>
						{siteTitle}
					</Link>
				</div>

				<div className="header-column center">
					<Link onClick={() => this.sendClickEvent('nav-view')} className="link nav" to="/">
						<svg className="icon"><use xlinkHref="#view" /></svg>
						<span class="text">Browse</span>
					</Link>
					<Link onClick={() => this.sendClickEvent('nav-edit')} className="link nav" to="/edit/">
						<svg className="icon"><use xlinkHref="#edit" /></svg>
						<span class="text">Add/Edit</span>
					</Link>
					<Link onClick={() => this.sendClickEvent('nav-sharepage')} className="link nav sharepage" to="/share/">
						<svg className="icon"><use xlinkHref="#share" /></svg>
						<span class="text">Share</span>
					</Link>
				</div>
				<div className="header-column right">
					<a onClick={() => this.sendClickEvent('follow-on-facebook')} className="link follow facebook" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/liorgrossman">
						<svg className="icon"><use xlinkHref="#social-facebook" /></svg>
						<span className="text">Follow</span>
					</a>
					<a onClick={() => this.sendClickEvent('follow-on-linkedin')} className="link follow linkedin" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/liorgrossman/">
						<svg className="icon twitter"><use xlinkHref="#social-linkedin" /></svg>
						<span className="text">Connect</span>
					</a>
					<a onClick={() => this.sendClickEvent('follow-on-twitter')} className="link follow twitter" target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/follow?screen_name=liorgrossman">
						<svg className="icon twitter"><use xlinkHref="#social-twitter" /></svg>
						<span className="text">Follow</span>
					</a>
				</div>


			</div>
		);
	}
}

Header.propTypes = {
	siteTitle: PropTypes.string,
};

Header.defaultProps = {
	siteTitle: ``,
};

export default Header;
