import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import faviconMetaTags from './favicon-meta-tags';
import metaImageUrl from '../images/themasterlist-meta-image.png';

function SEO({ location, description, lang, meta, keywords, title, titleNoPostfix }) {

	const getTitle = (data) => {
		if (!title) return data.site.siteMetadata.title;
		if (titleNoPostfix) {
			return title;
		} else {
			return title + " - " + data.site.siteMetadata.title;
		}
	};
	const getMetalinks = (link) => {
		var faviconMetaLinks = faviconMetaTags.get().filter(t => t.tag === "link");
		faviconMetaLinks.forEach(t => delete t.tag);
		return faviconMetaLinks;
	};

	const getMetadata = (data) => {

		const newTitle = getTitle(data);

		const newDescription =
			description || data.site.siteMetadata.description;

		var newImageUrl = metaImageUrl;
		if (newImageUrl.slice(0, 1) === "/") {
			newImageUrl = data.site.siteMetadata.host + newImageUrl;
		}


		// Still missing:
		// <meta property="fb:app_id" content="1234">
		// <meta property="og:url" content="http://">
		var metaArray = [{
				name: `description`,
				content: newDescription,
			},

			{
				itemprop: `name`,
				content: newTitle,
			},
			{
				itemprop: `description`,
				content: newDescription,
			},
			{
				itemprop: `image`,
				content: newImageUrl,
			},


			{
				name: `twitter:card`,
				content: `summary_large_image`,
			},
			{
				name: `twitter:site`,
				content: `@liorgrossman`,
			},
			{
				name: `twitter:creator`,
				content: `@liorgrossman`,
			},
			{
				name: `twitter:title`,
				content: newTitle,
			},
			{
				name: `twitter:description`,
				content: newDescription,
			},
			{
				name: `twitter:image:src`,
				content: newImageUrl,
			},


			{
				property: `og:title`,
				content: newTitle,
			},
			{
				property: `og:description`,
				content: newDescription,
			},
			{
				property: `og:image`,
				content: newImageUrl,
			},
			{
				property: `og:type`,
				content: `website`,
			},
			{
				property: `og:site_name`,
				content: data.site.siteMetadata.title,
			},
			{
				property: `og:locale`,
				content: `en_US`,
			},
			{
				property: `og:ttl`,
				content: `345600`,
			},
		];


		if (keywords.length > 0) {
			metaArray.push({
				name: `keywords`,
				content: keywords.join(`, `),
			});
		}
		var faviconMetaArray = faviconMetaTags.get().filter(t => t.tag === "meta");
		faviconMetaArray.forEach(t => delete t.tag);;

		metaArray = faviconMetaArray.concat(metaArray);
		return metaArray;
	};


	return (
		<StaticQuery
			query={detailsQuery}
			render={data => {
				return (
					<Helmet
						htmlAttributes={{
							lang,
							itemscope: "itemscope",
							itemtype: "http://schema.org/WebPage"
						}}
						title={getTitle(data)}
						link={getMetalinks(data)}
						meta={getMetadata(data)}
					>
					</Helmet>
				);
			}
			}
		/>
);
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	keywords: [],
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.array,
	keywords: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string.isRequired,
};

export default SEO;

const detailsQuery = graphql `
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
		host
      }
    }
  }
`;
