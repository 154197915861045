/*
<link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png?v=1" />
<link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png?v=1" />
<link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png?v=1" />
<link rel="manifest" href="/favicon/site.webmanifest?v=1" />
<link rel="mask-icon" href="/favicon/safari-pinned-tab.svg?v=1" color="#0077cc" />
<link rel="shortcut icon" href="/favicon.ico?v=1" />
<meta name="msapplication-TileColor" content="#0077cc" />
<meta name="msapplication-config" content="/favicon/browserconfig.xml?v=1" />
<meta name="theme-color" content="#0077cc" />
*/
var FAVICON_META_TAGS = [{
		tag: "link",
		rel: "apple-touch-icon",
		sizes: "180x180",
		href: "/favicon/apple-touch-icon.png?v=1"
	},
	{
		tag: "link",
		rel: "icon",
		type: "image/png",
		sizes: "32x32",
		href: "/favicon/favicon-32x32.png?v=1"
	},
	{
		tag: "link",
		rel: "icon",
		type: "image/png",
		sizes: "16x16",
		href: "/favicon/favicon-16x16.png?v=1"
	},
	{
		tag: "link",
		rel: "manifest",
		href: "/favicon/site.webmanifest?v=1"
	},
	{
		tag: "link",
		rel: "mask-icon",
		href: "/favicon/safari-pinned-tab.svg?v=1",
		color: "#0077cc"
	},
	{
		tag: "link",
		rel: "shortcut icon",
		href: "/favicon.ico?v=1"
	},
	{
		tag: "meta",
		name: "msapplication-TileColor",
		content: "#0077cc"
	},
	{
		tag: "meta",
		name: "msapplication-config",
		content: "/favicon/browserconfig.xml?v=1"
	},
	{
		tag: "meta",
		name: "theme-color",
		content: "#0077cc"
	}
];

export default {
	get: () => {
		return JSON.parse(JSON.stringify(FAVICON_META_TAGS));
	}
};
